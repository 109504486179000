.schedule {

  .rbc-time-gutter {
    .toolDiv {
      display: none !important;
    }
  }
  .rbc-event-content {
    display: initial !important;
  }

  .rbc-timeslot-group {
    z-index: 1;
    transition: 0s linear background-color .05s;

    &:hover {
      border-radius: 3px 4px 4px 3px;
      @media screen and (max-width: 600px) {
        background-color: transparent !important;
      }
    }
  }

  .disabled-time-slots-player {
    background-image: linear-gradient(145deg, #ffffff 30%, #f5cece 30%, #f5cece 50%, #ffffff 50%, #ffffff 80%, #f5cece 80%, #f5cece 100%);
    background-size: 8.72px 6.10px;
  }

  .rbc-time-gutter {
    .rbc-timeslot-group:has(.disabled-time-slot) {
      background: none;
    }

    .rbc-timeslot-group:has(.disabled-time-slots-player) {
      background: none;
    }
  }

  .noHover {
    // z-index: 0;
    .toolDiv {
      display: none !important;
    }
    &:hover {
      background-color: transparent !important;
    }
  }

  .rbc-time-gutter {
    z-index: 111 !important;

    .disabled-time-slot {
      background: transparent !important;
    }

    .disabled-time-slots-player {
      background: transparent !important;
    }

    .rbc-timeslot-group {
      // z-index: 0;
      &:hover {
        background-color: transparent !important;
      }
    }
  }

  .rbc-event {
    z-index: 11;
  }

  .changeWidth {
    .rbc-time-view-resources .rbc-day-slot {
      width: 100% !important;
      min-width: auto;
    }
  
    .rbc-row {
      width: initial !important;
    }
  
    @media (max-width: 750px) {
      .rbc-time-view-resources .rbc-day-slot, .rbc-row {
        width: 190px !important;
        min-width: 190px !important;
  
      }
    }
  }
  
  .oneCourtView {
    .rbc-time-view-resources .rbc-day-slot {
      width: 100% !important;
      min-width: auto;
    }
  
    .rbc-row {
      width: initial !important;
    }
  }
  
  .disabled {
    pointer-events: initial !important;
  }

  .rbc-current-time-indicator {
    border-top: 4px dashed;
    background-color: transparent;
    z-index: 999;
  }

  .rbc-time-header.rbc-overflowing {
    border-right: 0 !important;
  }

  .rbc-time-content .rbc-day-slot:nth-of-type(2) .rbc-current-time-indicator::before {
    $type: data-content;
    content: attr(data-content);
    width: 85px;
    padding: 6px;
    display: block;
    margin: -19px;
    z-index: 999999;
    font-size: 14px;
    border-radius: 7px;
    color: #fff;
    margin-left: -74px;
  }


  .rbc-header {
    font-size: 14px;
    font-weight: 500;
  }

  .rbc-time-header {
    .rbc-label {
      width: 82px !important;
      min-width: 82px !important;
      border-top: 1px solid #ddd;
    }
  }

  .rbc-time-gutter {
    .rbc-timeslot-group {
      width: 81px !important;
    }
  }

  .rbc-time-view-resources .rbc-day-slot {
    min-width: 220px;
    width: 220px;

  }

  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 0 !important;
    border-top: 1px solid #ddd;
  }

  .rbc-time-content {
    border-top: 1px solid #EEEEEE !important;
  }

  .rbc-time-content > * + * > * {
    border-left: 1px solid #EEEEEE !important;
  }

  .rbc-time-view {
    border: 1px solid #EEEEEE !important;
  }

  .rbc-timeslot-group {
    border-bottom: 1px solid #EEEEEE !important;
  }

  .rbc-timeslot-group {
    border-bottom: 1px solid #EEEEEE !important;
  }

  .rbc-row {
    min-height: 40px !important;
    width: 220px !important;
    display: flex;
    align-items: center;
  }

  @media (max-width: 767px) {
    .rbc-time-view-resources .rbc-day-slot, .rbc-row {
      width: 190px !important;
      min-width: 190px !important;
    }
  }

  .rbc-time-view {
    border-left: 0 !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    background: #fff !important;
  }

  .rbc-time-content, .rbc-time-header-gutter, .rbc-time-gutter {
    background: #fff !important;
  }

  .rbc-overflowing {
    border-radius: 0 !important;
    background: transparent !important;
  }

  .rbc-event .rbc-event-content {
    font-size: 14px !important;
    top: 13% !important;
    line-height: 1.57;
    justify-content: flex-start;
  }

  .rbc-timeslot-group {
    min-height: 49px !important;
  }

  .disableHover {
    .rbc-timeslot-group {
      z-index: 0;
    }
  }
}

.player-schedule {
  border: 1px solid #0000001F;
  border-radius: 12px;
  padding: 16px 0 16px 20px;
  @media screen and (max-width: 767px) {
    border: none;
    padding: 0;
  }

  .rbc-time-view {
    overflow: scroll !important;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      height: 7px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }

  .rbc-timeslot-group {
    &:hover {
      // border: 2px solid #ffffff !important;
      border-radius: 4px;
    }
  }

  .rbc-time-view {
    border-radius: 0 !important;
    border: 0 !important;
  }

  .paid, .unpaid, .cancelled, .maintenance {
    width: 100% !important;
  }

  .disableClicked {
    // pointer-events: none !important;
    opacity: 1;
    background-color: #E0E0E0 !important;
    border: 2px solid #ffffff !important;
    width: 100% !important;
    border-radius: 3px 4px 4px 3px;
    color: #263238 !important;
    border-left: 0 !important;
    border-top: 0 !important;
    padding-top: 1px;
    padding-left: 14px;
    z-index: 11;
  }

  .disableClicked::before {
    display: none !important;
  }
}