@import '_partials/layout';
@import '_partials/pricing';
@import "_partials/schedule";
@import 'swiper/css';

html{
  overflow: hidden;
}


