.pricing {

  .css-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #459ADF !important;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .rbc-toolbar {
    display: none !important;
  }

  .rbc-time-header {
    .rbc-row:nth-of-type(2) {
      display: none;
    }
  }

  .rbc-time-content > * + * > * {
    border-left: 1px solid #D9EAFF !important;
    text-align: center;
  }

  .rbc-timeslot-group {
    border-bottom: 1px solid #D9EAFF !important;
    min-height: 40px !important;
    border-bottom-left-radius: 3px;
    font-size: 12px;
    color: #71757A;
  }

  .rbc-time-slot {
    border: 0 !important;
    text-align: center;
  }

  .rbc-time-header > .rbc-row:first-of-type {
    border-bottom: none !important;
  }

  .rbc-time-view, .rbc-month-view, .rbc-agenda-view {
    border: 1px solid #EEEEEE !important;
    overflow: hidden;
    border-radius: 12px;
  }

  .rbc-overflowing {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: #ffffff;
  }

  .rbc-time-content {
    background: #ffffff;
    border-top: 1px solid #D9EAFF !important;
  }

  .rbc-label {
    span {
      position: relative;
      top: 18px;
    }
  }

  .rbc-header {
    text-align: center !important;
    padding: 5px;
    border-bottom: 0 !important;

  }

  .rbc-today {
    background-color: transparent !important;
  }

  .rbc-row > .rbc-header.rbc-now > a > span {
    color: #71757A !important;
  }

  .rbc-slot-selecting {
    border-radius: 0 !important;
    // z-index: 0;

  }

  .rbc-event, .rbc-selected {
    // width: 100% !important;
    border-radius: 5px;

    .rbc-event-content {
      position: relative;
      text-transform: capitalize;

      top: 50%;
      transform: translateY(-10%);
      display: flex;
      justify-content: center;
    }
  }

  .rbc-day-slot .rbc-event-label, .rbc-allday-cell {
    display: none !important;
  }

  .rbc-slot-selection {
    border-radius: 4px !important;
    width: 100% !important;
  }

  .rbc-day-slot .rbc-events-container {
    margin-right: 0 !important;
  }

  .rbc-addons-dnd-resizable {
    text-align: center !important;
  }

  .icons {
    margin-top: -2px;
  }

  .paid {
    background-color: #ECEFF1 !important;
  }

  .paid::before {
    content: "";
    background-color: #2CBC63;
  }

  .unpaid {
    background-color: #FBDBE0 !important;
  }

  .unpaid::before {
    content: "";
    background-color: #EB4962;
  }

  .maintenance, .closed, .training, .event, .tournament {
    background-color: #F2F5F7 !important;
  }

  .maintenance::before {
    content: "";
    background-color: rgba(229, 164, 11, 1);
  }

  .closed::before {
    content: "";
    background-color: rgba(229, 164, 11, 1);
  }

  .training::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.87);
  }

  .event::before {
    content: "";
    background-color: rgba(103, 58, 183, 1);
  }

  .tournament::before {
    content: "";
    background-color: rgba(0, 188, 212, 1);
  }

  .paid, .unpaid, .cancelled, .maintenance, .closed, .training, .event, .tournament {
    border: 2px solid #ffffff !important;
    width: 100% !important;
    border-radius: 3px 4px 4px 3px;
    color: #263238 !important;
    border-left: 0 !important;
    border-top: 0 !important;
    padding-top: 1px;
    padding-left: 14px;
  }

  .paid::before, .unpaid::before, .cancelled::before, .maintenance::before, .tournament::before, .event::before, .training::before, .closed::before {
    height: 100%;
    top: 0%;
    width: 7px;
    position: absolute;
    left: 0%;
    border-radius: 6px;
  }
}

.pricingChanges {
  .rbc-event, .rbc-selected {
    border: 1px solid #ffffff !important;
    background-color: rgba($color: #1E88E5, $alpha: 1) !important;
  }
}

.sizingWidth {
  .rbc-row {
    @media (min-width: 1360px) {
      width: 100% !important;
    }
  }

  @media (max-width: 750px) {
    .rbc-time-view-resources .rbc-day-slot, .rbc-row {
      width: 190px !important;
      min-width: 190px !important;

    }
  }
  // @media (min-width: 1360px) {
  //   .rbc-day-slot {
  //     min-width: auto !important;
  //     width: 100% !important;
  //   }

  // }
}

.changeWidth {
  .rbc-time-view-resources .rbc-day-slot {
    width: 100% !important;
    min-width: auto;
  }

  .rbc-row {
    width: initial !important;
  }

  @media (max-width: 750px) {
    .rbc-time-view-resources .rbc-day-slot, .rbc-row {
      width: 190px !important;
      min-width: 190px !important;

    }
  }
}

.oneCourtView {
  .rbc-time-view-resources .rbc-day-slot {
    width: 100% !important;
    min-width: auto;
  }

  .rbc-row {
    width: initial !important;
  }
}

.courtPricing {
  .rbc-time-gutter {
    .rbc-time-slot {
      background-color: #ffffff !important;
    }
  }

  .rbc-header {
    border-bottom: 1px solid #EEEEEE !important;
  }

  .rbc-agenda-view {
    td {
      border-color: #EEEEEE !important;
      font-size: 14px;
    }
  }

  .rbc-agenda-content {
    overflow: auto;
  }

  .rbc-event-content {
    p {
      color: #fff !important;
    }
  }

  .normalDay {
    width: 100% !important;

    .rbc-agenda-event-cell {
      border: 2px solid #fff !important;
      border-left: 2px solid #fff !important;
      padding: 8px 16px !important;
      border-radius: 6px;
      background: #2196F3;

      p {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    cursor: pointer;
    left: 0% !important;

  }

  .rbc-agenda-time-cell {
    padding: 8px 15px !important;
  }

  .rbc-agenda-date-cell {
    padding: 8px 16px !important;
  }

  .agendaView {
    border-top: 1px solid #EEEEEE !important;

    .rbc-agenda-event-cell {
      background-color: #26B55D !important;
      border: 2px solid #fff !important;
      border-left: 2px solid #fff !important;
      border-radius: 6px;
      padding: 8px 16px !important;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer !important;

      p {
        color: rgba(0, 0, 0, 0.6);
      }
    }

  }

  .rbc-month-row {
    .specificDay {
      cursor: pointer !important;
      width: 100% !important;
    }
  }

  .rbc-current-time-indicator {
    display: none !important;
  }

  .rbc-event {
    .rbc-event-content {
      position: initial !important;
      align-items: center !important;
    }
  }

  .specificDay {
    background-color: #26B55D !important;
    cursor: pointer !important;
    width: 100% !important;
    left: 00% !important;
    z-index: 9;
    opacity: 1;

    .chip {
      display: block;
      font-size: 14px;
      text-align: center;
      font-weight: 500;
    }

    .rbc-event-content {
      opacity: 1 !important;
      text-transform: capitalize;
      transform: translateY(3px);
    }
  }

  .rbc-header {
    span {
      font-family: Public Sans, sans-serif !important;
      font-weight: 500 !important;
      text-transform: uppercase;
      font-size: 14px;
    }
  }

  .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    font-family: Public Sans, sans-serif !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    font-size: 14px;
  }

  .rbc-agenda-table {
    border: 0 !important;
  }

  .css-17xourg-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
    border-bottom: none !important;
  }

  .css-17xourg-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
    border-bottom: none !important;
  }

  .css-5f0fg3-MuiSelect-select-MuiInputBase-input-MuiInput-input {
    padding-bottom: 0 !important;
  }

}

.css-woedqb-MuiPopper-root-MuiPickersPopper-root {
  width: auto !important;
}

.pricingDialog {
  .datePricker {
    .css-5z83bo-MuiInputBase-root-MuiInput-root:before, .css-5z83bo-MuiInputBase-root-MuiInput-root:after, .css-5z83bo-MuiInputBase-root-MuiInput-root:hover {
      border-bottom: none;
    }
  }
}